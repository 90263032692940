<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit())">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg
                    src="media/svg/icons/Communication/Dial-numbers.svg"
                  />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/articles/items/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                {{ $t('ARTICLES.ALL_ARTICLES') }}
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="onSubmit(article, 'new')"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  {{ $t('ARTICLES.UPDATE_ARTICLE') }}
                </button>
                <button
                  @click="toggleSubmitOptions"
                  type="button"
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div
                  class="js-submit-options-dropdown dropdown-menu dropdown-menu-sm dropdown-menu-right"
                >
                  <ul class="nav nav-hover flex-column">
                    <li class="nav-item">
                      <a
                        @click="onSubmit(article, 'exit')"
                        href="#"
                        class="nav-link"
                      >
                        <i class="nav-icon flaticon2-power"></i>
                        <span class="nav-text">{{
                          $t('GENERAL.SAVE_AND_EXIT')
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <ValidationProvider
                    name="name"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <div class="form-group row">
                      <label class="col-3">{{
                        $t('ARTICLES.ARTICLE_NAME')
                      }}</label>
                      <div class="col-9">
                        <input
                          v-model="article.name"
                          class="form-control form-control-solid"
                          :placeholder="$t('ARTICLES.ARTICLE_NAME')"
                          type="text"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                  <div class="form-group row">
                    <label class="col-3">{{ $t('GENERAL.AMOUNT') }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.quantity"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('GENERAL.AMOUNT')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{
                      $t('ARTICLES.NET_PURCHASE_PRICE')
                    }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.netPurchasePrice"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('ARTICLES.NET_PURCHASE_PRICE')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{
                      $t('ARTICLES.NET_PURCHASE_PRICE')
                    }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.netSalesPrice"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('ARTICLES.NET_SELLING_PRICE')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{
                      $t('ARTICLES.GROSS_PURCHASE_PRICE')
                    }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.grossPurchasePrice"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('ARTICLES.GROSS_PURCHASE_PRICE')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{
                      $t('ARTICLES.GROSS_SALES_PRICE')
                    }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.grossSalesPrice"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('ARTICLES.GROSS_SALES_PRICE')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{ $t('GENERAL.VAT') }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.pdv"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="25%"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{ $t('ARTICLES.UNIT') }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.unit"
                        class="form-control form-control-solid"
                        type="text"
                        :placeholder="$t('ARTICLES.UNIT')"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-3">{{ $t('ARTICLES.SUPPLIER') }}</label>
                    <div class="col-9">
                      <input
                        v-model="article.supplier"
                        class="form-control form-control-solid"
                        placeholder="Konzum"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ArticleEdit',
  data() {
    return {
      article: {
        name: null,
        quantity: null,
        netPurchasePrice: null,
        netSalesPrice: null,
        grossPurchasePrice: null,
        grossSalesPrice: null,
        company: null,
        pdv: null,
        unit: null,
        supplier: null,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Articles', route: '/articles/items/list' },
      { title: 'Edit Articles' },
    ])

    ApiService.get('articles', this.$route.params.id).then(({ data }) => {
      this.article.name = data.name
      this.article.quantity = data.quantity
      this.article.netPurchasePrice = data.netPurchasePrice
      this.article.netSalesPrice = data.netSalesPrice
      this.article.grossPurchasePrice = data.grossPurchasePrice
      this.article.grossSalesPrice = data.grossSalesPrice
      this.article.company = data.company
      this.article.pdv = data.pdv
      this.article.unit = data.unit
      this.article.supplier = data.supplier
    })
  },
  methods: {
    onSubmit() {
      ApiService.update('articles', this.$route.params.id, this.article)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Artikl uspješno dodan.',
          })

          setTimeout(() => {
            this.$router.push({ name: 'articles-items-list' })
          }, 500)
        })
        .catch(() => {})
    },
    toggleSubmitOptions() {
      document.querySelector('.js-submit-options').classList.toggle('show')
      document
        .querySelector('.js-submit-options-dropdown')
        .classList.toggle('show')
    },
  },
}
</script>

<style lang="scss"></style>
